import './index.css';
import React from "react";
import Main from './components/MainPage'

function App() {
  return (
    <div className="w-screen h-screen bg-black grid place-items-center">
<Main/>
    </div>
  );
}

export default App;
